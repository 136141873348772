import { ParsedUrlQuery } from 'querystring'
import { VerticalConfigTag } from 'entities/tag'
import { StandardVertical, allVerticals, communityVerticals } from 'entities/vertical'
import { isServerSide } from 'lib/env'

type CommonVerticalConfig = {
    platformName: string
    labelName: string
    description: string
    linkedin: string
    twitter: string
    jobsboardHeader: {
        title: string
        mobileTitle?: string
    }
    footerJobLinks: VerticalConfigTag[]
    footerArticleLinks: VerticalConfigTag[]
    articlesTags: VerticalConfigTag[] // are not used yet, waiting for the Articles page
}

type NormalVerticalConfig = CommonVerticalConfig & {
    blogDescription: string // is not used yet, waiting for the Articles page
}

type CompanyVerticalConfig = CommonVerticalConfig

export type VerticalConfig = NormalVerticalConfig | CompanyVerticalConfig

type VerticalsConfig = Record<StandardVertical, VerticalConfig>

const www: CompanyVerticalConfig = {
    platformName: 'WorksHub',
    labelName: 'WorksHub',
    description:
        'WorksHub helps you source and hire the best tech talent. Sign up now to advertise your roles, showcase your product, and access targeted developer communities',
    jobsboardHeader: {
        title: 'Software Engineering Jobs',
        mobileTitle: 'Jobs',
    },
    linkedin: 'https://www.linkedin.com/company/workshub',
    twitter: '@workshub',
    footerJobLinks: [
        { label: 'JavaScript', slug: 'javascript' },
        { label: 'TypeScript', slug: 'typescript' },
        { label: 'Scala', slug: 'scala' },
        { label: 'Java', slug: 'java' },
        { label: 'Clojure', slug: 'clojure' },
        { label: 'Python', slug: 'python' },
        { label: 'Haskell', slug: 'haskell' },
        { label: 'Go', slug: 'go' },
        { label: 'Ruby', slug: 'ruby' },
        { label: 'Rust', slug: 'rust' },
        { label: 'Elixir', slug: 'elixir' },
        { label: 'Blockchain', slug: 'blockchain' },
    ],
    footerArticleLinks: [
        { label: 'JavaScript', slug: 'javascript' },
        { label: 'TypeScript', slug: 'typescript' },
        { label: 'React', slug: 'react' },
        { label: 'Python', slug: 'python' },
        { label: 'Go', slug: 'go' },
        { label: 'Java', slug: 'java' },
        { label: 'Scala', slug: 'scala' },
        { label: 'Clojure', slug: 'clojure' },
        { label: 'Haskell', slug: 'haskell' },
        { label: 'Frontend', slug: 'frontend' },
        { label: 'Blockchain', slug: 'blockchain' },
        { label: 'Functional Programming', slug: 'functional-programming' },
    ],
    articlesTags: [
        { label: 'JavaScript', slug: 'javascript' },
        { label: 'TypeScript', slug: 'typescript' },
        { label: 'React', slug: 'react' },
        { label: 'Python', slug: 'python' },
        { label: 'Go', slug: 'go' },
        { label: 'Java', slug: 'java' },
        { label: 'Scala', slug: 'scala' },
        { label: 'Clojure', slug: 'clojure' },
        { label: 'Haskell', slug: 'haskell' },
        { label: 'Frontend', slug: 'frontend' },
        { label: 'Blockchain', slug: 'blockchain' },
        { label: 'Functional Programming', slug: 'functional-programming' },
    ],
}

const java: NormalVerticalConfig = {
    platformName: 'Java Works',
    labelName: 'Java',
    description:
        'Browse Java developer jobs, salaries, blogs and learning resources! Java jobs, articles and more.',
    jobsboardHeader: {
        title: 'Java Developer Jobs',
        mobileTitle: 'Java Jobs',
    },
    blogDescription:
        'A curated blog on Java programming. Read news, posts and opinions from the community - we have Java covered.',
    linkedin: 'https://www.linkedin.com/company/javaworks',
    twitter: '@Java_workshub',
    footerJobLinks: [
        { label: 'Java', slug: 'java' },
        { label: 'Scala', slug: 'scala' },
        { label: 'Kotlin', slug: 'kotlin' },
        { label: 'Clojure', slug: 'clojure' },
        { label: 'Kubernetes', slug: 'kubernetes' },
        { label: 'AWS', slug: 'amazon-aws' },
        { label: 'Akka', slug: 'akka' },
        { label: 'Kafka', slug: 'kafka' },
        { label: 'Spark', slug: 'spark' },
        { label: 'Mobile', slug: 'mobile' },
        { label: 'Android', slug: 'android' },
        { label: 'Data Engineering', slug: 'data-engineering' },
    ],
    footerArticleLinks: [
        { label: 'Java', slug: 'java' },
        { label: 'Scala', slug: 'scala' },
        { label: 'Kotlin', slug: 'kotlin' },
        { label: 'Clojure', slug: 'clojure' },
        { label: 'OOP', slug: 'oop' },
        { label: 'Functional Programming', slug: 'functional-programming' },
        { label: 'Microservices', slug: 'microservices' },
        { label: 'Android', slug: 'android' },
        { label: 'Ethereum', slug: 'ethereum' },
        { label: 'Kafka', slug: 'kafka' },
        { label: 'Spark', slug: 'spark' },
        { label: 'Spring Framework', slug: 'spring-framework' },
    ],
    articlesTags: [
        { label: 'Java', slug: 'java' },
        { label: 'Scala', slug: 'scala' },
        { label: 'Kotlin', slug: 'kotlin' },
        { label: 'Clojure', slug: 'clojure' },
        { label: 'OOP', slug: 'oop' },
        { label: 'Functional Programming', slug: 'functional-programming' },
        { label: 'Microservices', slug: 'microservices' },
        { label: 'Android', slug: 'android' },
        { label: 'Ethereum', slug: 'ethereum' },
        { label: 'Kafka', slug: 'kafka' },
        { label: 'Spark', slug: 'spark' },
        { label: 'Spring Framework', slug: 'spring-framework' },
    ],
}

const remote: NormalVerticalConfig = {
    platformName: 'Remote Works',
    labelName: 'Remote',
    description:
        'Browse remote software engineering jobs, salaries, blogs and learning resources!',
    jobsboardHeader: {
        title: 'Remote Jobs',
    },
    blogDescription:
        'A curated blog on the remote working. Read news, posts and opinions from the community - we have remote covered.',
    linkedin: 'https://www.linkedin.com/company/workshub',
    twitter: '@Remote_Works_',
    footerJobLinks: [
        { label: 'JavaScript', slug: 'javascript' },
        { label: 'TypeScript', slug: 'typescript' },
        { label: 'Node.js', slug: 'node-js' },
        { label: 'Python', slug: 'python' },
        { label: 'Scala', slug: 'scala' },
        { label: 'Clojure', slug: 'clojure' },
        { label: 'C++', slug: 'cplusplus' },
        { label: 'Go', slug: 'go' },
        { label: 'Rust', slug: 'rust' },
        { label: 'Haskell', slug: 'haskell' },
        { label: 'Ethereum', slug: 'ethereum' },
        { label: 'Blockchain', slug: 'blockchain' },
    ],
    footerArticleLinks: [
        { label: 'Remote Working', slug: 'remote-working' },
        { label: 'Remote Team Management', slug: 'remote-team-management' },
        { label: 'Tech Startups', slug: 'tech-startups' },
        { label: 'Startup', slug: 'startup' },
        { label: 'Hiring', slug: 'hiring' },
        { label: 'Diversity', slug: 'diversity' },
        { label: 'Open Source', slug: 'open-source' },
        { label: 'Guide', slug: 'guide' },
        { label: 'Job Seeking', slug: 'job-seeking' },
        { label: 'Interview Questions', slug: 'interview-questions' },
        { label: 'Interview Tips', slug: 'interview-tips' },
        { label: 'Technical Writing', slug: 'technical-writing' },
    ],
    articlesTags: [
        { label: 'Remote Working', slug: 'remote-working' },
        { label: 'Remote Team Management', slug: 'remote-team-management' },
        { label: 'Tech Startups', slug: 'tech-startups' },
        { label: 'Startup', slug: 'startup' },
        { label: 'Hiring', slug: 'hiring' },
        { label: 'Diversity', slug: 'diversity' },
        { label: 'Open Source', slug: 'open-source' },
        { label: 'Guide', slug: 'guide' },
        { label: 'Job Seeking', slug: 'job-seeking' },
        { label: 'Interview Questions', slug: 'interview-questions' },
        { label: 'Interview Tips', slug: 'interview-tips' },
        { label: 'Technical Writing', slug: 'technical-writing' },
    ],
}

const blockchain: NormalVerticalConfig = {
    platformName: 'Blockchain Works',
    labelName: 'Blockchain',
    description:
        'Browse blockchain news, jobs, salaries and learning resources! Jobs in the United States, UK and more.',
    jobsboardHeader: {
        title: 'Blockchain Jobs',
    },
    blogDescription:
        'A curated blog on blockchain technology. Read news, posts and opinions from the community - we have blockchain covered.',
    linkedin: 'https://www.linkedin.com/company/blockchainworks/',
    twitter: '@blockchainworks',
    footerJobLinks: [
        { label: 'Blockchain', slug: 'blockchain' },
        { label: 'Ethereum', slug: 'ethereum' },
        { label: 'Cardano', slug: 'cardano' },
        { label: 'Smart Contracts', slug: 'smart-contracts' },
        { label: 'Solidity', slug: 'solidity' },
        { label: 'DAML', slug: 'daml' },
        { label: 'Web3', slug: 'web3' },
        { label: 'dApps', slug: 'dapps' },
        { label: 'Substrate', slug: 'substrate' },
        { label: 'Cosmos SDK', slug: 'cosmos-sdk' },
        { label: 'DeFi', slug: 'defi' },
        { label: 'Zero-knowledge proofs', slug: 'zero-knowledge-proofs' },
    ],
    footerArticleLinks: [
        { label: 'Blockchain', slug: 'blockchain' },
        { label: 'Crypto', slug: 'crypto' },
        { label: 'DeFi', slug: 'defi' },
        { label: 'Cryptocurrency', slug: 'cryptocurrency' },
        { label: 'Bitcoin', slug: 'bitcoin' },
        { label: 'Ethereum', slug: 'ethereum' },
        { label: 'Web3', slug: 'web3' },
        { label: 'dApps', slug: 'dapps' },
        { label: 'Tokens', slug: 'tokens' },
        { label: 'NFT', slug: 'nft' },
        { label: 'Smart Contracts', slug: 'smart-contracts' },
        { label: 'Solidity', slug: 'solidity' },
    ],
    articlesTags: [
        { label: 'Blockchain', slug: 'blockchain' },
        { label: 'Crypto', slug: 'crypto' },
        { label: 'DeFi', slug: 'defi' },
        { label: 'Cryptocurrency', slug: 'cryptocurrency' },
        { label: 'Bitcoin', slug: 'bitcoin' },
        { label: 'Ethereum', slug: 'ethereum' },
        { label: 'Web3', slug: 'web3' },
        { label: 'dApps', slug: 'dapps' },
        { label: 'Tokens', slug: 'tokens' },
        { label: 'NFT', slug: 'nft' },
        { label: 'Smart Contracts', slug: 'smart-contracts' },
        { label: 'Solidity', slug: 'solidity' },
    ],
}

const ai: NormalVerticalConfig = {
    platformName: 'AI Works',
    labelName: 'Artificial Intelligence',
    description:
        'Browse artificial intelligence, machine learning, deep learning jobs and learning resources! Put your Python, R, Spark and Scala skills to work. Jobs in the US, UK and multiple locations.',
    jobsboardHeader: {
        title: 'AI / ML Jobs',
    },
    blogDescription:
        'A curated blog on artificial intelligence, machine learning, deep learning and related topics.',
    linkedin: 'https://www.linkedin.com/company/aiworks/',
    twitter: '@AIWorksBot',
    footerJobLinks: [
        { label: 'Machine Learning', slug: 'machine-learning' },
        { label: 'Data Engineering', slug: 'data-engineering' },
        { label: 'Data Science', slug: 'data-science' },
        { label: 'NLP', slug: 'nlp' },
        { label: 'Python', slug: 'python' },
        { label: 'Scala', slug: 'scala' },
        { label: 'Java', slug: 'java' },
        { label: 'JavaScript', slug: 'javascript' },
        { label: 'R', slug: 'r' },
        { label: 'TensorFlow', slug: 'tensorflow' },
        { label: 'PyTorch', slug: 'pytorch' },
        { label: 'Spark', slug: 'spark' },
    ],
    footerArticleLinks: [
        { label: 'Data Science', slug: 'data-science' },
        { label: 'Artificial Intelligence', slug: 'artificial-intelligence' },
        { label: 'Deep Learning', slug: 'deep-learning' },
        { label: 'Machine Learning', slug: 'machine-learning' },
        { label: 'Reinforcement Learning', slug: 'reinforcement-learning' },
        { label: 'Data Analysis', slug: 'data-analysis' },
        { label: 'Python', slug: 'python' },
        { label: 'R', slug: 'r' },
        { label: 'Scala', slug: 'scala' },
        { label: 'TensorFlow', slug: 'tensorflow' },
        { label: 'Spark', slug: 'spark' },
        { label: 'Big Data', slug: 'big-data' },
    ],
    articlesTags: [
        { label: 'Data Science', slug: 'data-science' },
        { label: 'Artificial Intelligence', slug: 'artificial-intelligence' },
        { label: 'Deep Learning', slug: 'deep-learning' },
        { label: 'Machine Learning', slug: 'machine-learning' },
        { label: 'Reinforcement Learning', slug: 'reinforcement-learning' },
        { label: 'Data Analysis', slug: 'data-analysis' },
        { label: 'Python', slug: 'python' },
        { label: 'R', slug: 'r' },
        { label: 'Scala', slug: 'scala' },
        { label: 'TensorFlow', slug: 'tensorflow' },
        { label: 'Spark', slug: 'spark' },
        { label: 'Big Data', slug: 'big-data' },
    ],
}

export const functional: NormalVerticalConfig = {
    platformName: 'Functional Works',
    labelName: 'Functional programming',
    description:
        'Browse functional programming jobs, salaries, blogs and learning resources! Scala jobs, Haskell jobs, Clojure jobs and more.',
    jobsboardHeader: {
        title: 'Functional Programming Jobs',
        mobileTitle: 'FP Jobs',
    },
    blogDescription:
        'A curated blog on functional programming. Read news, posts and opinions from the community - we have FP covered.',
    linkedin: 'https://www.linkedin.com/company/functional-works/',
    twitter: '@functionalworks',
    footerJobLinks: [
        { label: 'Scala', slug: 'scala' },
        { label: 'Clojure', slug: 'clojure' },
        { label: 'ClojureScript', slug: 'clojurescript' },
        { label: 'Kotlin', slug: 'kotlin' },
        { label: 'Haskell', slug: 'haskell' },
        { label: 'Elixir', slug: 'elixir' },
        { label: 'Erlang', slug: 'erlang' },
        { label: 'OCaml', slug: 'ocaml' },
        { label: 'F#', slug: 'fsharp' },
        { label: 'Elm', slug: 'elm' },
        { label: 'PureScript', slug: 'purescript' },
        { label: 'ReasonML', slug: 'reasonml' },
    ],
    footerArticleLinks: [
        { label: 'Functional Programming', slug: 'functional-programming' },
        { label: 'Typed Functional Programming', slug: 'typed-functional-programming' },
        { label: 'Functional Workplaces', slug: 'functional-workplaces' },
        { label: 'Clojure', slug: 'clojure' },
        { label: 'ClojureScript', slug: 'clojurescript' },
        { label: 'Haskell', slug: 'haskell' },
        { label: 'Scala', slug: 'scala' },
        { label: 'Elixir', slug: 'elixir' },
        { label: 'Erlang', slug: 'erlang' },
        { label: 'Elm', slug: 'elm' },
        { label: 'OCaml', slug: 'ocaml' },
        { label: 'F#', slug: 'fsharp' },
    ],
    articlesTags: [
        { label: 'Functional Programming', slug: 'functional-programming' },
        { label: 'Typed Functional Programming', slug: 'typed-functional-programming' },
        { label: 'Functional Workplaces', slug: 'functional-workplaces' },
        { label: 'Clojure', slug: 'clojure' },
        { label: 'ClojureScript', slug: 'clojurescript' },
        { label: 'Haskell', slug: 'haskell' },
        { label: 'Scala', slug: 'scala' },
        { label: 'Elixir', slug: 'elixir' },
        { label: 'Erlang', slug: 'erlang' },
        { label: 'Elm', slug: 'elm' },
        { label: 'OCaml', slug: 'ocaml' },
        { label: 'F#', slug: 'fsharp' },
    ],
}

const javascript: NormalVerticalConfig = {
    platformName: 'JavaScript Works',
    labelName: 'JavaScript',
    description:
        'Browse JavaScript jobs, salaries, blogs and learning resources! Jobs in the United States, UK and more.',
    jobsboardHeader: {
        title: 'JavaScript Jobs',
    },
    blogDescription:
        'A curated blog on JavaScript tech. Read news, posts and opinions from the community - we have JavaScript covered.',
    linkedin: 'https://www.linkedin.com/company/javascriptworks/',
    twitter: '@javascriptworks',
    footerJobLinks: [
        { label: 'JavaScript', slug: 'javascript' },
        { label: 'TypeScript', slug: 'typescript' },
        { label: 'Node.js', slug: 'node-js' },
        { label: 'React', slug: 'react' },
        { label: 'Next.js', slug: 'next-js' },
        { label: 'Angular', slug: 'angular' },
        { label: 'Vue.js', slug: 'vue-js' },
        { label: 'Svelte', slug: 'svelte' },
        { label: 'React Native', slug: 'react-native' },
        { label: 'Blockchain', slug: 'blockchain' },
        { label: 'Web3', slug: 'web3' },
        { label: 'Functional Programming', slug: 'functional-programming' },
    ],
    footerArticleLinks: [
        { label: 'JavaScript', slug: 'javascript' },
        { label: 'TypeScript', slug: 'typescript' },
        { label: 'React', slug: 'react' },
        { label: 'Angular', slug: 'angular' },
        { label: 'Vue.js', slug: 'vue-js' },
        { label: 'Svelte', slug: 'svelte' },
        { label: 'Node.js', slug: 'node-js' },
        { label: 'GraphQL', slug: 'graphql' },
        { label: 'Redux', slug: 'redux' },
        { label: 'CSS', slug: 'css' },
        { label: 'HTML5', slug: 'html5' },
        { label: 'Functional Programming', slug: 'functional-programming' },
    ],
    articlesTags: [
        { label: 'JavaScript', slug: 'javascript' },
        { label: 'TypeScript', slug: 'typescript' },
        { label: 'React', slug: 'react' },
        { label: 'Angular', slug: 'angular' },
        { label: 'Vue.js', slug: 'vue-js' },
        { label: 'Svelte', slug: 'svelte' },
        { label: 'Node.js', slug: 'node-js' },
        { label: 'GraphQL', slug: 'graphql' },
        { label: 'Redux', slug: 'redux' },
        { label: 'CSS', slug: 'css' },
        { label: 'HTML5', slug: 'html5' },
        { label: 'Functional Programming', slug: 'functional-programming' },
    ],
}

const python: NormalVerticalConfig = {
    platformName: 'Python Works',
    labelName: 'Python',
    description:
        'Browse Python programming jobs, salaries, blogs and learning resources! Python jobs, Django jobs, Flask jobs and more.',
    jobsboardHeader: {
        title: 'Python Developer Jobs',
        mobileTitle: 'Python Jobs',
    },
    blogDescription:
        'A curated blog on the Python programming language. Read news, posts and opinions from the community - we have Python covered.',
    linkedin: 'https://www.linkedin.com/company/python-workshub/',
    twitter: '@python_works',
    footerJobLinks: [
        { label: 'Python', slug: 'python' },
        { label: 'Django', slug: 'django' },
        { label: 'Flask', slug: 'flask' },
        { label: 'FastAPI', slug: 'fastapi' },
        { label: 'DevOps', slug: 'devops' },
        { label: 'Machine Learning', slug: 'machine-learning' },
        { label: 'Data Science', slug: 'data-science' },
        { label: 'Data Engineering', slug: 'data-engineering' },
        { label: 'TensorFlow', slug: 'tensorflow' },
        { label: 'PyTorch', slug: 'pytorch' },
        { label: 'scikit-learn', slug: 'scikit-learn' },
        { label: 'pandas', slug: 'pandas' },
    ],
    footerArticleLinks: [
        { label: 'Python', slug: 'python' },
        { label: 'Django', slug: 'django' },
        { label: 'JavaScript', slug: 'javascript' },
        { label: 'TensorFlow', slug: 'tensorflow' },
        { label: 'PyTorch', slug: 'pytorch' },
        { label: 'Data', slug: 'data' },
        { label: 'Data Science', slug: 'data-science' },
        { label: 'Machine Learning', slug: 'machine-learning' },
        { label: 'Databases', slug: 'databases' },
        { label: 'Blockchain', slug: 'blockchain' },
        { label: 'DevOps', slug: 'devops' },
        { label: 'Functional Programming', slug: 'functional-programming' },
    ],
    articlesTags: [
        { label: 'Python', slug: 'python' },
        { label: 'Django', slug: 'django' },
        { label: 'JavaScript', slug: 'javascript' },
        { label: 'TensorFlow', slug: 'tensorflow' },
        { label: 'PyTorch', slug: 'pytorch' },
        { label: 'Data', slug: 'data' },
        { label: 'Data Science', slug: 'data-science' },
        { label: 'Machine Learning', slug: 'machine-learning' },
        { label: 'Databases', slug: 'databases' },
        { label: 'Blockchain', slug: 'blockchain' },
        { label: 'DevOps', slug: 'devops' },
        { label: 'Functional Programming', slug: 'functional-programming' },
    ],
}

const golang: NormalVerticalConfig = {
    platformName: 'Golang Works',
    labelName: 'Golang',
    description: 'Browse Golang jobs, salaries, blogs and learning resources!',
    jobsboardHeader: {
        title: 'Golang Jobs',
    },
    blogDescription:
        'A curated blog on Golang. Read news, posts and opinions from the community - we have Golang covered.',
    linkedin: 'https://www.linkedin.com/company/golang-works/',
    twitter: '@golangworks',
    footerJobLinks: [
        { label: 'Go', slug: 'go' },
        { label: 'Python', slug: 'python' },
        { label: 'DevOps', slug: 'devops' },
        { label: 'Linux', slug: 'linux' },
        { label: 'Terraform', slug: 'terraform' },
        { label: 'Ansible', slug: 'ansible' },
        { label: 'Docker', slug: 'docker' },
        { label: 'Kubernetes', slug: 'kubernetes' },
        { label: 'AWS', slug: 'amazon-aws' },
        { label: 'Blockchain', slug: 'blockchain' },
        { label: 'Distributed Systems', slug: 'distributed-systems' },
        { label: 'Microservices', slug: 'microservices' },
    ],
    footerArticleLinks: [
        { label: 'Go', slug: 'go' },
        { label: 'Rust', slug: 'rust' },
        { label: 'C++', slug: 'cplusplus' },
        { label: 'Distributed Systems', slug: 'distributed-systems' },
        { label: 'Microservices', slug: 'microservices' },
        { label: 'Docker', slug: 'docker' },
        { label: 'Kubernetes', slug: 'kubernetes' },
        { label: 'AWS', slug: 'amazon-aws' },
        { label: 'Google Cloud', slug: 'google-cloud' },
        { label: 'DevOps', slug: 'devops' },
        { label: 'Security', slug: 'security' },
        { label: 'Blockchain', slug: 'blockchain' },
    ],
    articlesTags: [
        { label: 'Go', slug: 'go' },
        { label: 'Rust', slug: 'rust' },
        { label: 'C++', slug: 'cplusplus' },
        { label: 'Distributed Systems', slug: 'distributed-systems' },
        { label: 'Microservices', slug: 'microservices' },
        { label: 'Docker', slug: 'docker' },
        { label: 'Kubernetes', slug: 'kubernetes' },
        { label: 'AWS', slug: 'amazon-aws' },
        { label: 'Google Cloud', slug: 'google-cloud' },
        { label: 'DevOps', slug: 'devops' },
        { label: 'Security', slug: 'security' },
        { label: 'Blockchain', slug: 'blockchain' },
    ],
}

export const allVerticalsConfig: VerticalsConfig = {
    www,
    java,
    remote,
    blockchain,
    ai,
    functional,
    javascript,
    python,
    golang,
}

export type VerticalOption = {
    value: StandardVertical
    label: string
}

/**
 * An array of <value, label> pairs for community verticals
 * for UI output (for use within selects, checkboxes, etc.).
 */
export const verticalOptions: VerticalOption[] = communityVerticals.map(vertical => {
    return {
        value: vertical,
        label: allVerticalsConfig[vertical].platformName,
    }
})

/**
 * Extracts the current vertical either from a query string or from a host.
 *
 * @param hostname - the current URL host name, e.g. 'functional.works-hub'
 * @param query - a `ParsedUrlQuery`
 */
export const extractVertical = (
    hostname: string,
    query: ParsedUrlQuery,
): StandardVertical => {
    // 1. extract it from a query param 'sample.com/?vertical=functional' -> functional
    const queryVertical = query.vertical as StandardVertical
    if (queryVertical && allVerticals.includes(queryVertical)) {
        return queryVertical
    }

    // 2. no hostname -> www
    if (!hostname) return 'www'

    // 3. try to understand the vertical by parsing the hostname, for example
    //    'https://functional.works-hub.com/'       -> `hostname` = 'functional.works-hub'
    //    'functional.works-hub'.match(/(\w+).\w+/) -> ['functional.works', 'functional']
    //    ['functional.works', 'functional'][1]     -> 'functional'
    const verticalStr = hostname.match(/(\w+).\w+/)
    if (
        verticalStr &&
        verticalStr[1] &&
        allVerticals.includes(verticalStr[1] as StandardVertical)
    ) {
        return verticalStr[1] as StandardVertical
    }

    // 4. default -> www
    return 'www'
}

/**
 * Builds a URL for a specific `vertical` on the client-side.
 *
 * @param vertical - a `StandardVertical`
 */
export const getVerticalUrlOnClient = (vertical: StandardVertical) => {
    if (isServerSide) {
        throw new Error(`Can't get URL of vertical '${vertical}' on server side`)
    }

    return window.location.origin.replace(
        /(https?):\/\/[^.]+(\..*)/g,
        `$1://${vertical}$2`,
    )
}

/**
 * Builds a URL for a specific `vertical` based on the way our `origin` looks like.
 *
 * @param protocol - either 'http' or 'https'
 * @param origin - a URL string of the 'window.location.origin' format
 * @param vertical - a `StandardVertical`
 */
export const getVerticalUrl = (
    protocol: 'http' | 'https',
    origin: string,
    vertical: StandardVertical,
) => {
    return origin.replace(/https?:\/\/[^.]+(\..*)/g, `${protocol}://${vertical}$1`)
}
