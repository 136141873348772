export type Vertical =
    | 'functional'
    | 'blockchain'
    | 'javascript'
    | 'ai'
    | 'golang'
    | 'java'
    | 'python'
    | 'remote'

export type StandardVertical = Vertical | typeof companyVertical

// values for run-time

export const companyVertical = 'www'

export const defaultVertical: StandardVertical = companyVertical

export const communityVerticals: Vertical[] = [
    'functional',
    'blockchain',
    'javascript',
    'ai',
    'golang',
    'java',
    'python',
    'remote',
]

export const allVerticals: StandardVertical[] = [...communityVerticals, companyVertical]

// projections

export type BlogVertical = StandardVertical
